import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import CartBannerComponent from "../../Components/ProdectBanner/CartBannerComponent";
import { authHeader, numberWithCommas } from "../../utils";
import { authOperations } from "../../state/ducs/auth";
import { cartOperations } from "../../state/ducs/cart";
import "./index.css";

const ItemCard = ({ title, iconClass, itemValue }) => {
  return (
    <div className="itemcard">
      <div className="iconContainer">
        <i className={iconClass}></i>
      </div>
      <h4>{itemValue}</h4>
      <p>{title}</p>
    </div>
  );
};

const MyAccounts = ({ cartItems,
  removeFromCart,
  handleSectionRef,
  setHomePageSearchedResultProducts,
  signIn,
  logout,
  removeUser,
  userInfo,
  isAuthenticated,
  clearCart }) => {
  const [orders, setOrders] = useState([]);

  const navigate = useNavigate();

  //get all the orders by the the user
  const getOrders = (userId) => {
    axios
      .post("https://ap.japanidokan.com/api/order-list-user", {
        user_id: userId
      }, {
        headers: authHeader(),
      })
      .then((response) => {
        console.log(response);
        const { data } = response;
        setOrders(data);
      })
      .catch((error) => {
        console.log(error);
      })
      .then(() => {
        // always executed
      });
  };

  useEffect(() => {
    console.log("user", userInfo.id);
    if (userInfo.id) {
      getOrders(userInfo.id);
    }
  }, [userInfo]);

  const editProfile = () => {
    navigate("/edit");
  }

  const handleLogout = () => {
    if (cartItems.length > 0) {

      const modifiedCartItems = cartItems.map((item) => {
        return {
          product_id: item.id,
          quantity: item.quantity,
          user_id: userInfo.id,
        };
      });

      try {
        axios
          .post("https://ap.japanidokan.com/api/cart-items", { cart_items: modifiedCartItems })
          .then(function (response) {
            console.log(response);
            if (response.status === 201 || response.status === 200) {


              logout();
              removeUser();
              navigate("/");
              clearCart();
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      } finally {
      }

    } else {
      logout();
      removeUser();
      navigate("/");
      clearCart();
    }




  };

  return (
    <div>
      <CartBannerComponent title="Account" />
      <div className="container">
        <div className="row content">
          <div className="col-md-12 decration cards-background">
            <h4 className="pb-4">My Dashboard</h4>
            <div className="row">
              <div className="col-md-3">
                <ItemCard
                  title={"Total Order"}
                  iconClass="fa fa-shopping-cart"
                  itemValue={orders.length}
                />
              </div>
              <div className="col-md-3">
                <ItemCard
                  title={"Total Wishlist"}
                  iconClass="fa fa-heart"
                  itemValue={0}
                />
              </div>
              <div className="col-md-3">
                <ItemCard
                  title={"Total Download"}
                  iconClass="fa fa-cloud-download"
                  itemValue={0}
                />
              </div>
              <div className="col-md-3">
                <ItemCard
                  title={"Total Transactions"}
                  iconClass="fa fa-money"
                  itemValue={0}
                />
              </div>
              <div onClick={() => editProfile()} className="col-md-3">
                <ItemCard
                  title={"Edit Your Account Information"}
                  iconClass="fa fa-user"
                  itemValue={""}

                />
              </div>
              <div className="col-md-3">
                <ItemCard
                  title={"Change Your Password"}
                  iconClass="fa fa-key"
                  itemValue={""}
                />
              </div>
              <div className="col-md-3">
                <ItemCard
                  title={"Modify your address book entries"}
                  iconClass="fa fa-address-book"
                  itemValue={""}
                />
              </div>
              <div className="col-md-3">
                <ItemCard
                  title={"Modify your wishlist"}
                  iconClass="fa fa-heart"
                  itemValue={""}
                />
              </div>
              <div className="col-md-3">
                <ItemCard
                  title={"View your order history"}
                  iconClass="fa fa-undo"
                  itemValue={""}
                />
              </div>
              <div className="col-md-3">
                <ItemCard
                  title={"Downloads"}
                  iconClass="fa fa-cloud-download"
                  itemValue={""}
                />
              </div>
              <div className="col-md-3">
                <ItemCard
                  title={"Your Reward Points"}
                  iconClass="fa fa-shield"
                  itemValue={""}
                />
              </div>
              <div className="col-md-3">
                <ItemCard
                  title={"View your return requests"}
                  iconClass="fa fa-retweet"
                  itemValue={""}
                />
              </div>
              <div className="col-md-3">
                <ItemCard
                  title={"Total Transactions"}
                  iconClass="fa fa-money"
                  itemValue={""}
                />
              </div>
              <div className="col-md-3">
                <ItemCard
                  title={"Recurring Payments"}
                  iconClass="fa fa-usd"
                  itemValue={""}
                />
              </div>
              <div className="col-md-3">
                <ItemCard
                  title={"Subscribe / unsubscribe to newsletter"}
                  iconClass="fa fa-envelope"
                  itemValue={""}
                />
              </div>
              <div style={{ cursor: "pointer" }} onClick={() => handleLogout()} className="col-md-3">
                <ItemCard
                  title={"Logout"}
                  iconClass="fa fa-sign-out"
                  itemValue={""}
                />
              </div>
            </div>
            <div className="row">
              <div class="container">
                <h4 className="pb-2">Latest Orders</h4>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Order ID</th>
                      <th>Customer</th>
                      <th>No. of Products</th>
                      <th>Status </th>
                      <th>Total</th>
                      <th>Date Added</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.length &&
                      orders.map((order) => (
                        <tr>
                          <td>{order.id}</td>
                          <td>{userInfo.name}</td>
                          <td>{order.order_product.length}</td>
                          <td>{order.status}</td>
                          <td>৳{numberWithCommas(order.pay_amount)}</td>
                          <td> {order.updated_at.split("T")[0]}</td>
                          <td>
                            <i className="fa fa-eye"></i>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* <Myaccount /> */}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cart,
    isAuthenticated: state.auth.token !== null,
    userInfo: state.auth.user,
  };
};

const mapDispatchToProps = {
  removeFromCart: cartOperations.removeFromCart,
  logout: authOperations.logout,
  removeUser: authOperations.removeAuthUser,
  clearCart: cartOperations.clearCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccounts);
